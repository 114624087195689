.sol-lottery-detail-tabs {
	margin-bottom: 20px;
	h3 {
		font-size: 20px;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: 0px;
		color: var(--sol-color);
		margin-bottom: 20px;
	}
	.tab-list {
		display: flex;
		width: 100%;
		column-gap: 20px;
		border-bottom: 1px solid #48378d;
		display: inline-flex;
		.sol-tab {
			padding: 0 0 10px 0;
			flex: none;
			white-space: nowrap;
			font-size: 15px;
			font-weight: 300;
			letter-spacing: 0px;
			color: var(--sol-color);
			text-transform: none;
			border-bottom-width: 6px;
			line-height: 19px;
		}
	}
}
