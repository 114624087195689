.sol-item-card {
	padding: 15px;
	border: 1px transparent solid;
	border-radius: 10px;
	background-color: var(--sol-white-05);
	transition: all ease-in-out 0.3s;
	height: 60px;
	display: flex;
	align-items: center;
	column-gap: 10px;
	color: var(--sol-color-heading);
	font-weight: 700;
	&:disabled {
		opacity: 0.5;
	}
	&:not(:disabled) {
		&:hover {
			border-color: var(--sol-app-card-border);
		}
	}
	&-icon {
		width: 36px;
		height: 36px;
		min-width: 36px;
		overflow: hidden;
		border-radius: 5px;
		img,
		svg {
			width: 100;
			height: 100%;
			object-fit: contain;
		}
	}
	&-right-icon {
		margin-left: auto;
	}
}
