.sol-lottery-completed {
	.row {
		--bs-gutter-x: 32px;
		--bs-gutter-y: 32px;
		@media (max-width: 767px) {
			--bs-gutter-x: 24px;
			--bs-gutter-y: 24px;
		}
	}
}

.no-items {
	width: 100%;
	border: 1px solid var(--sol-color-primary);
	padding: 40px 50px 40px 40px;
	border-radius: 19px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: var(--sol-app-card-bg);
}
