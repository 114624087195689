.sol-available-on {
	display: inline-flex;
	align-items: center;
	column-gap: 16px;
	white-space: nowrap;
	line-height: 1;
	&.lg {
		font-size: 18px;
	}
	@media(max-width:991px){
		span{
			display: none;
		}
	}
	a {
		color: var(--sol-color);
		transition: all ease-in-out 0.3s;
		&:hover {
			color: var(--sol-color-heading);
			svg {
				transform: scale(1.1);
			}
		}
		svg {
			min-width: 20px;
			width: 20px;
			height: 20px;
			transition: transform ease-in-out 0.3s;
			transform-origin: center center;
		}
	}
}
