.sol-launchpad-detail-join-pool-dialog {
	.sol-form-group {
		margin-bottom: 30px;
		.sol-form-label {
			color: var(--sol-color-heading);
			margin-bottom: 8px;
		}
		.sol-input-amount {
			position: relative;
			.sol-input {
				width: 100%;
				padding: 10px 15px;
				height: 56px;
				border-radius: 10px;
				border: 1px transparent solid;
				transition: all ease-in-out 0.3s;
				color: var(--sol-color-heading);
				font-size: 20px;
				font-weight: 900;
				background-color: var(--sol-app-card-bg);
				padding-right: 75px;
				-moz-appearance: textfield; /* Firefox */
				&:focus {
					outline: 0;
					border-color: var(--sol-app-card-border);
				}
				&::-webkit-outer-spin-button,
				&::-webkit-inner-spin-button {
					/* display: none; <- Crashes Chrome on hover */
					-webkit-appearance: none;
					margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
				}
			}
			.sol-btn-max {
				background-color: transparent;
				border: 1px var(--sol-color-primary) solid;
				transition: all ease-in-out 0.3s;
				display: inline-flex;
				padding: 10px;
				border-radius: 5px;
				color: var(--sol-color-heading);
				font-size: 14px;
				line-height: 1;
				font-weight: 900;
				position: absolute;
				top: 50%;
				right: 15px;
				transform: translateY(-50%);
				&:hover {
					background-color: var(--sol-color-primary);
				}
			}
		}
		.sol-input-amount-balance {
			text-align: right;
			font-size: 14px;
			b {
				color: var(--sol-color-heading);
			}
		}
	}
}
