@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.transaction-link {
	text-decoration: none;
	color: #757575;
}

.transaction-link span {
	color: #5ac8fa;
}

.transaction-link.c-white {
	color: #fff !important;
}
