.sol-card {
	display: flex;
	padding: 30px;
	flex-direction: column;
	border-radius: 24px;
	background: rgba(255, 255, 255, 0.03);
	@media (max-width: 767px) {
		padding: 20px;
		border-radius: 20px;
	}

	&-header {
		display: flex;
		align-items: center;
		border-left: 2px solid var(--sol-color-primary);

		.sol-card-title {
			font-size: 16px;
			padding: 10px 0px 10px 20px;
			color: var(--sol-color-heading);
			font-weight: 700;
			text-transform: uppercase;
			margin: 0;
			line-height: 1;
			white-space: nowrap;
			@media (max-width: 1199px) {
				font-size: 16px;
			}
		}
		.sol-card-action {
			margin-left: auto;
			color: var(--sol-color-primary);
			font-size: 14px;
			font-weight: 500;
			text-transform: uppercase;
			transition: all ease-in-out 0.3s;
			cursor: pointer;
			&:hover {
				color: var(--sol-color-heading);
			}
		}
	}

	&-body {
		padding: 30px 0px 12px 0px;
	}
}
