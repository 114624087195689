.sol-pool-image {
	width: 100%;
	height: 280px;
	position: relative;
	border-radius: 20px;
	overflow: hidden;
	@media(max-width:1399px){
		height: auto;
	}
	&.type-2 {
		height: 350px;
		border-radius: 28px;
		@media(max-width:1399px){
			height: auto;
		}
		.sol-network {
			padding: 8px;
			left: 50%;
			right: auto;
			transform: translateX(-50%);
			border-radius: 10px;
			.sol-network-icon{
				width: 24px;
				max-width: 24px;
				height: 24px;
			}
		}
	}
	> img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.sol-network {
		position: absolute;
		right: 20px;
		bottom: 20px;
		display: inline-flex;
		padding: 5px;
		justify-content: center;
		align-items: center;
		gap: 4px;
		border-radius: 5px;
		background: var(--sol-bg-tag-color);
		backdrop-filter: blur(3px);
		color: var(--sol-color);
		font-size: 13px;
		white-space: nowrap;
		b {
			color: var(--sol-color-heading);
			font-weight: 700;
			text-transform: uppercase;
		}
		&-icon {
			width: 20px;
			max-width: 20px;
			height: 20px;
			border-radius: 5px;
			overflow: hidden;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
}
