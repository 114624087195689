.sol-lottery-card {
	padding-top: 66px;
	position: relative;
	display: block;
	text-align: center;
	text-decoration: none;
	white-space: nowrap;
	width: 100%;
	&:hover{
		.inner{
			box-shadow: 0px 0px 50px 10px var(--sol-box-shadow-color);
		}
	}
	.inner {
		padding: 114px 20px 52px;
		background: transparent linear-gradient(180deg, #1c1246 0%, #0d0f31 100%) 0%
			0% no-repeat padding-box;
		border: 1px solid #48378d;
		border-radius: 19px;
		transition: all ease-in-out .3s;
	}
	.image {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		height: 153px;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		img {
			max-height: 100%;
			object-fit: contain;
		}
	}
	.name {
		font-size: 32px;
		line-height: 36px;
		font-weight: 600;
		letter-spacing: 0px;
		color: var(--sol-color-primary);
		margin-bottom: 12px;
	}
	.price {
		font-size: 36px;
		line-height: normal;
		font-weight: 600;
		letter-spacing: 0px;
		color: #ffffff;
		position: relative;
		margin-bottom: 28px;
		padding-bottom: 16px;
		&::after {
			content: '';
			position: absolute;
			width: 87px;
			height: 4px;
			background: #9345ec 0% 0% no-repeat padding-box;
			border-radius: 4px;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
		}
	}
	.more {
		font-size: 17px;
		line-height: 19px;
		display: flex;
		flex-direction: column;
		row-gap: 12px;
		align-items: center;
		justify-content: center;
		column-gap: 16px;
		b {
			color: var(--sol-color-primary);
		}
	}
}
