.sol-modal {
	.modal-dialog {
		margin-top: 150px;
		@media (min-width: 576px) {
			max-width: 420px;
			&.modal-lg {
				max-width: 700px;
			}
		}
	}
	.modal-content {
		background-color: var(--sol-bg-modal);
		border-radius: 20px;
	}
	.modal-header {
		padding: 20px 30px;
		border: 0;
		@media (max-width: 767px) {
			padding: 20px;
		}
		.modal-title {
			color: var(--sol-color-heading);
			font-size: 24px;
			font-weight: 900;
			margin-bottom: 0;
			@media (max-width: 767px) {
				font-size: 20px;
			}
		}
		.btn-close {
			filter: invert(1);
		}
	}
	.modal-body {
		padding: 10px 30px 30px;
		@media (max-width: 767px) {
			padding: 10px 20px 20px;
		}
	}
	.modal-footer {
		border: 0;
	}
}
.modal-backdrop {
	background-color: var(--sol-bg-modal-backdrop);
	&.show {
		opacity: 0.8 !important;
	}
}
