.sol-btn.btn {
	font-size: 14px;
	line-height: 16px;
	font-weight: 600;
	padding: 10px 24px;
	border-radius: 4px;
	transition: all ease-in-out 0.3s;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	column-gap: 4px;
	white-space: nowrap;
	&.btn-outline-success {
		--bs-btn-color: var(--sol-color-primary);
		--bs-btn-border-color: var(--sol-color-primary);
		--bs-btn-hover-color: #000;
		--bs-btn-hover-bg: var(--sol-color-primary);
		--bs-btn-hover-border-color: var(--sol-color-primary);
		--bs-btn-focus-shadow-rgb: 25, 135, 84;
		--bs-btn-active-color: #000;
		--bs-btn-active-bg: var(--sol-color-primary);
		--bs-btn-active-border-color: var(--sol-color-primary);
		--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
		--bs-btn-disabled-color: var(--sol-color-primary);
		--bs-btn-disabled-bg: transparent;
		--bs-btn-disabled-border-color: var(--sol-color-primary);
		--bs-gradient: none;
	}
	&.btn-success {
		--bs-btn-color: #000;
		--bs-btn-bg: var(--sol-color-primary);
		--bs-btn-border-color: var(--sol-color-primary);
		--bs-btn-hover-color: #000;
		--bs-btn-hover-bg: #1bb960;
		--bs-btn-hover-border-color: var(--sol-color-primary);
		--bs-btn-focus-shadow-rgb: 60, 153, 110;
		--bs-btn-active-color: #000;
		--bs-btn-active-bg: #146c43;
		--bs-btn-active-border-color: #13653f;
		--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
		--bs-btn-disabled-color: #000;
		--bs-btn-disabled-bg: var(--sol-color-primary);
		--bs-btn-disabled-border-color: var(--sol-color-primary);
	}
}

.wallet-adapter-button.wallet-adapter-button-trigger {
	border: 0;
	background: rgb(6, 78, 253);
	background: linear-gradient(
		45deg,
		rgb(0, 63, 221) 0%,
		rgb(24, 255, 113) 100%
	);
	position: relative;
	overflow: hidden;
	min-width: 140px;
	border-radius: 24px;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: -50px;
		width: 10px;
		height: 100%;
		transform: skewX(15deg);
		background-color: #fff;
		transition: all ease-in-out 0.5s;
		filter: blur(3px);
		opacity: 0.8;
	}
	&:not(:disabled) {
		&:hover {
			opacity: 0.9;
			background-color: var(--sol-color-primary);
			&::before {
				left: 120%;
			}
		}
	}

	&:disabled {
		opacity: 0.5;
		pointer-events: none;
	}
}
