.sol-overlay {
	display: none;
	position: fixed;
	bottom: 0;
	right: 0;
	width: calc(100% - var(--sol-sidebar-width));
	height: calc(100% - 90px);
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 9999;
	justify-content: center;
	align-items: center;

	@media (max-width: 1200px) {
		width: 100%;
	}
	&.show {
		display: flex;
        align-items: center;
        justify-content: center;
	}

    &-content {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        gap: 12px;
        color: #fff;
    }
}
