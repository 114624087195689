.sol-info {
	padding-right: 20px;
	display: flex;
	flex-direction: column;
	row-gap: 8px;
	&-label {
		u {
			text-decoration: none;
			font-weight: 600;
			color: #fff;
		}
		@media (max-width: 767px) {
			line-height: 1;
		}
	}

	span {
		color: var(--sol-color-heading);
		font-size: 20px;
		font-weight: 900;
		white-space: nowrap;
	}

	&-value {
		color: var(--sol-color-heading);
		font-size: 15px;
		font-weight: 900;
		white-space: nowrap;
	}
	&.lg {
		@media (min-width: 1200px) {
			font-size: 18px;
			.sol-info-value {
				font-size: 20px;
			}
		}
	}
}
