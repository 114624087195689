.sol-app-template {
	overflow: hidden;
	position: relative;
	.decor {
		position: absolute;
		z-index: -1;
		top: 0px;
		right: -300px;
		// animation: zoom-out 20s infinite;
		@media (max-width: 576px) {
			display: none;
		}
	}
	.sol-main {
		min-height: 100vh;
		position: relative;
		padding: var(--sol-main-padding);
		@media (max-width: 1399px) {
			padding: 100px 40px 40px calc(40px + 220px);
		}
		@media (max-width: 767px) {
			padding: 100px 20px 24px 20px;
			min-height: 100px;
			height: 100vh;
			overflow-y: auto;
		}
	}
}
.sol-app-template,
.sol-modal {
	.sol-btn.btn {
		border-radius: 100px;
		height: 46px;
		padding: 15px 22px;
		&.btn-sm {
			font-size: 13px;
			height: 42px;
		}
		&.btn-lg {
			height: 57px;
			padding: 20px 42px;
		}
		&.btn-success {
			background: rgb(6, 78, 253);
			background: linear-gradient(
				45deg,
				rgb(0, 63, 221) 0%,
				rgb(24, 255, 113) 100%
			);
			color: #fff;
			border: 0;
		}
	}
}
