.sol-launchpad-banner {
	@media (max-width: 1199px) {
		padding: 30px 0;
	}
	@media (max-width: 991px) {
		.sol-page-title {
			br {
				display: none;
			}
		}
	}
	@media (max-width: 767px) {
		padding-bottom: 0;
	}
	&-description {
		font-size: 18px;
	}
	&-statistics {
		padding: 40px 0 10px;
		display: flex;
		flex-direction: wrap;
		justify-content: space-between;
		@media (max-width: 767px) {
			flex-wrap: wrap;
			column-gap: 30px;
		}
		.sol-statistic {
			position: relative;
			@media (max-width: 767px) {
				width: calc(50% - 15px);
				margin-bottom: 20px;
				.sol-statistic-label{
					white-space: nowrap;
				}
			}
			&:not(:last-child) {
				&:after {
					content: '';
					position: absolute;
					top: 0;
					right: 10px;
					width: 1px;
					height: 100%;
					background: var(--sol-app-gradient-color);
					@media (max-width: 1399px) {
						right: 12px;
						opacity: 0.5;
					}
					@media (max-width: 767px) {
						display: none;
					}
				}
			}
		}
	}
}
