.sol-leadership {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: calc(100% - 107px);
	table {
		--bs-table-bg: transparent !important;
		--bs-table-border-color: transparent !important;
		--bs-border-width: 10px !important;
		margin-bottom: 0;
		white-space: nowrap;
		th {
			font-size: 14px;
			line-height: 16px;
			letter-spacing: 0px;
			color: #48378d;
			padding: 0 8px 14px;
			font-weight: 300;
			border: 0;
		}
		tbody {
			tr {
				td {
					font-size: 14px;
					line-height: 16px;
					font-weight: 600;
					color: #ffffff;
					text-transform: uppercase;
					position: relative;
					vertical-align: middle;
					* {
						position: relative;
					}
					&:first-child {
						&::before {
							border-radius: 6px 0 0 6px;
						}
					}
					&:last-child {
						&::before {
							border-radius: 0 6px 6px 0;
						}
					}
					&::before {
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background: #261a55 0% 0% no-repeat padding-box;
					}
				}
			}
		}
		.user-cell {
			display: flex;
			column-gap: 16px;
			align-items: center;
			.avatar {
				width: 44px;
				height: 44px;
				border: 0.5px solid #9345ec;
				border-radius: 50%;
				padding: 5px;
				span {
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 50%;
					overflow: hidden;
					img {
						max-width: 100%;
						max-height: 100%;
						object-fit: contain;
					}
				}
			}
			.info {
				display: flex;
				flex-direction: column;
				row-gap: 4px;
				font-weight: 300;
				color: #48378d;
				text-transform: none;
				b {
					font-weight: 600;
					color: #afafaf;
					text-transform: uppercase;
				}
			}
		}
	}
}

.info-prevent-copy {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: transparent !important;
	text-shadow: 0 0 8px #fff !important;
}
