.sol-launchpad-detail-pool-card {
	border-radius: 30px;
	padding: 32px;
	background-color: var(--sol-white-03);
	backdrop-filter: blur(5px);
	&.active {
		background: var(--sol-bg-primary);
		box-shadow: 0px 0px 50px 10px var(--sol-box-shadow-color);
	}
	@media (max-width: 767px) {
		padding: 20px;
		border-radius: 20px;
	}
	&-left {
		.sol-info {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	&-right {
		&.connected {
			border-left: 1px solid var(--sol-border-secondary);
			padding-left: 40px;
			@media (max-width: 991px) {
				border-left: 0;
				border-top: 1px solid var(--sol-border-secondary);
				padding: 0;
			}
		}
		@media (max-width: 991px) {
			padding-top: 20px;
		}
		@media (max-width: 767px) {
			margin-bottom: 0;
		}
	}
	&-action {
		display: flex;
		align-items: center;
		gap: 15px;
		padding-top: 20px;
		border-top: 1px solid var(--sol-border-secondary);
		margin-top: 20px;
		@media (max-width: 767px) {
			flex-direction: column;
		}
		.sol-btn {
			min-width: 200px;
			@media (max-width: 767px) {
				width: 100%;
			}
		}
	}
	.sol-info {
		margin-bottom: 24px;
	}
	.sol-progress-bar {
		margin-top: 4px;
	}
	.sol-progress-bar-info {
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: var(--sol-color-heading);
		margin-top: 8px;
	}
}
