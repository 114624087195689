.sol-dashboard-news-lg {
	position: relative;
	border-radius: 20px;
	overflow: hidden;
	background-size: cover;
	background-position: top center;
	display: flex;
	align-items: flex-end;
	padding: 40px;
	height: calc(100% - 32px);
	background-color: var(--sol-app-card-bg);
	@media (max-width: 1399px) {
		height: calc(100% - 24px);
	}
	@media (max-width: 1199px) {
		padding: 30px;
	}
	@media (max-width: 991px) {
		height: 300px;
		margin-bottom: 24px;
	}
	@media (max-width: 1199px) {
		height: 420px;
		padding: 24px;
		margin-bottom: 20px;
		border-radius: 20px;
	}
	&::before {
		content: '';
		position: absolute;
		width: 100%;
		height: 70%;
		left: 0;
		bottom: 0;
		background: linear-gradient(180deg, rgba(38, 39, 59, 0) 0%, #26273b 59%);
	}
	* {
		position: relative;
	}
	&-title {
		display: flex;
		text-decoration: none;
		color: var(--sol-color-heading);
		font-size: 18px;
		font-weight: bold;
		margin-bottom: 10px;
		transition: all ease-in-out 0.3s;
		&:hover {
			color: var(--sol-color-primary);
			transform: translateY(-10px);
		}
	}
	&-description {
		font-size: 16px;
	}
}
