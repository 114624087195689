.sol-dashboard-news {
	&-title {
		color: var(--sol-color-heading);
		font-size: 18px;
		font-weight: bold;
		margin-bottom: 40px;
		@media (max-width: 1399px) {
			margin-bottom: 20px;
		}
	}
}
