.sol-loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    &-logo {
        &.md {
            width: 48px;
            height: 48px;
        }

        &.lg {
            width: 80px;
            height: 80px;
        }
       
    }

    h1 {
        font-size: 48px;
        font-weight: 800;
        line-height: normal;
    }
}

