.sol-dashboard-news-md {
	position: relative;
	display: flex;
	min-height: 190px;
	padding: 20px 40px;
	align-items: center;
	border-radius: 20px;
	background: var(--sol-app-card-bg);
	overflow: hidden;
	margin-bottom: 32px;
	@media (max-width: 1399px) {
		margin-bottom: 24px;
	}
	@media (max-width: 1199px) {
		min-height: 160px;
		padding: 20px 30px;
	}
	@media (max-width: 767px) {
		padding: 20px 24px;
		min-height: 120px;
		margin-bottom: 20px;
		border-radius: 20px;
	}
	&.last {
		img {
			top: auto;
			bottom: 0;
		}
	}
	&-title {
		display: flex;
		color: var(--sol-color-heading);
		font-size: 18px;
		font-weight: bold;
		margin-bottom: 0;
		width: 240px;
		max-width: 100%;
		text-decoration: none;
		transition: all ease-in-out 0.3s;
		position: relative;
		z-index: 2;
		@media (max-width: 1199px) {
			width: auto;
		}
		&:hover {
			color: var(--sol-color-primary);
			transform: translateY(-10px);
			~ img {
				transform: translateX(10px);
				opacity: 0.6;
			}
		}
	}
	img {
		position: absolute;
		top: 0;
		right: 0;
		transition: all ease-in-out 0.3s;
		@media (max-width: 1199px) {
			max-width: 60%;
		}
		@media (max-width: 767px) {
			max-width: 40%;
		}
	}
}
