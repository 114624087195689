.sol-pagination {
	margin-bottom: 0;
	.page-item,
	.break,
	.next,
	.previous {
		width: 30px;
		height: 30px;
		padding: 0 6px;
		background-color: inherit;
		color: rgba(255, 255, 255, 0.65);
		text-align: center;
		font-size: 14px;
		font-weight: 500;
		line-height: 28px; /* 200% */

		&:hover {
			color: var(--sol-color-primary);
			scale: 1.1;
			transition: all 0.1s ease-in-out;

			g {
				opacity: 1;
			}
		}
	}

	.page-item.active {
		border-radius: 3px;
		background: var(--sol-color-primary);
		color: #fff;
	}

	.previous {
		margin-right: 10px;
	}

	.next {
		margin-left: 10px;
	}
}
