.sol-lottery-machine {
    display: flex;
    align-items: center;
    overflow: hidden;
    border: 1px solid var(--sol-color-primary);;
    border-radius: 4px;
    &-items {
        height: 30px;
        width: 30px;
        background-color: gray;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2px;
    }
}