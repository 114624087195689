@import '~bootstrap/scss/bootstrap';
:root {
	--sol-sidebar-width: 260px;
	--sol-header-width: calc(100% - 260px);
	--sol-main-padding: 100px 60px 60px calc(60px + 260px);
	--sol-bg: #0d0f31;
	--sol-bg-card: #131539;
	--sol-bg-primary: #131539;
	--sol-bg-secondary: #26273b;
	--sol-bg-modal: #202136;
	--sol-bg-modal-backdrop: #100e29;
	--sol-bg-sidebar-backdrop: rgba(#100e29, 0.9);
	--sol-bg-tag-color: #1B1C3199;
	--sol-color: #e6e6e6;
	--sol-color-heading: #fff;
	--sol-color-primary: #23e777;
	--sol-color-warning: #f0b90b;
	--sol-color-danger: #e65062;
	--sol-color-highlight: #0045ff;
	--sol-border-color: #111e6c;
	--sol-border-secondary: rgba(255, 255, 255, 0.1);
	--sol-box-shadow-color: rgba(189, 0, 255, 0.1);
	--sol-white-02: rgba(255, 255, 255, 0.02);
	--sol-white-03: rgba(255, 255, 255, 0.03);
	--sol-white-05: rgba(255, 255, 255, 0.05);
	--sol-app-card-bg: #191b42;
	--sol-app-sidebar-bg: #191b42;
	--sol-app-sidebar-color: #a6abb1;
	--sol-app-sidebar-hover-color: #fff;
	--sol-app-input-border: rgba(35, 231, 119, 0.5);
	--sol-app-card-border: rgba(35, 231, 119, 0.5);
	--sol-app-gradient-color: transparent linear-gradient(209deg, #23FF65 0%, #0045FF 100%) 0% 0% no-repeat padding-box;
}
body {
	max-width: 100vw;
	background-color: var(--sol-bg);
	font-family: Ubuntu, sans-serif !important;
	font-size: 16px;
	font-weight: 300;
	line-height: 1.5 !important;
	letter-spacing: 0px;
	color: var(--sol-color);
	position: relative;
	overflow-x: hidden;
}

::-webkit-scrollbar {
	width: 12px;
	height: 12px;
}

::-webkit-scrollbar-track {
	background-color: rgba($color: #000, $alpha: 0.3);
}

::-webkit-scrollbar-thumb {
	background: var(--sol-app-card-bg);
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	/* display: none; <- Crashes Chrome on hover */
	-webkit-appearance: none;
	margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
	-moz-appearance: textfield; /* Firefox */
}

input[type='checkbox'] {
	position: relative;
	cursor: pointer;
	margin-top: 2px;
	width: 20px;
	height: 20px;
	outline: 0;
	&:before {
		content: '';
		display: block;
		position: absolute;
		width: 20px;
		height: 20px;
		top: 0;
		left: 0;
		border: 1px solid var(--sol-color);
		border-radius: 3px;
		background-color: var(--sol-app-card-bg);
		transition: all ease-in-out 0.3s;
	}
	&:checked {
		&:before {
			background-color: var(--sol-color-primary);
		}
		&:after {
			content: '';
			display: block;
			width: 5px;
			height: 10px;
			border: solid var(--sol-color-heading);
			border-width: 0 2px 2px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
			position: absolute;
			top: 4px;
			left: 8px;
		}
	}
}
