.sol-your-wallet-dialog {
	.sol-wallet-info {
		padding: 24px 20px 20px;
		color: var(--sol-color-heading);
		text-align: center;
		border-radius: 10px;
		background: var(--sol-white-03);
		margin-bottom: 30px;
		.sol-wallet-address {
			font-size: 20px;
			font-weight: 900;
			word-wrap: break-word;
		}
		.sol-wallet-message {
			color: var(--sol-color-primary);
			font-size: 14px;
			line-height: 1;
			margin-top: 10px;
			height: 14px;
		}
	}
	.sol-wallet-action {
		display: flex;
		align-items: center;
		column-gap: 15px;
		.sol-btn {
			flex: 1;
		}
	}
}

.sol-modal-processing.loading,
.sol-modal-processing.rejected,
.sol-modal-processing.done {
	display: flex;
	align-items: center;
	flex-direction: column;
	text-align: center;
	gap: 10px;

	.sol-modal-processing-header {
		color: var(--sol-color-primary);
		font-weight: 600;
		font-size: 20px;

		display: flex;
		align-items: center;
		justify-content: space-around;
		text-align: center;
		max-width: 60%;
		gap: 12px;

		h2 {
			font-size: 24px;
			font-weight: 700;
			line-height: normal;
			margin: 0;
		}
	}

	.sol-modal-processing-desc {
		.error {
			color: var(--bs-red);
		}
	}

	.sol-modal-processing-btns {
		width: 100%;
		display: flex;
		gap: 10px;
		align-items: center;
		margin-top: 24px;
		justify-content: space-around;
		.sol-btn {
			width: 50%;
		}
	}

	.sol-modal-number-machine {
		flex-wrap: wrap;
		text-align: center;
		justify-content: center;
	}
}
