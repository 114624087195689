.sol-token-template {
	&-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: rgb(34 34 62);
		padding: 1rem;
		border-radius: 8px;
		margin-bottom: 16px;

		h2 {
			font-size: 1.25rem;
			line-height: 1.75rem;
			font-weight: 700;
		}

		span {
			font-size: 0.875rem;
			line-height: 1.25rem;
			font-weight: 500;
			color: #a6abb1;
		}
	}

	&-infor {
		padding-left: 30px;
		position: relative;

		.btn-group {
			background-color: rgb(23 23 42 / 1);
			border-radius: 9999px;
			height: 38px;
			display: flex;
			align-items: center;
			width: 244px;
			.btn-check {
				display: none;
			}
			.btn.btn-primary {
				height: 36px;
				display: flex;
				align-items: center;
				justify-content: center;
				background: inherit;
				padding: 8px;
				--bs-btn-border-color: inherit;
				outline: none;
				border-radius: 9999px !important;
				overflow: hidden;

				&.active {
					background: linear-gradient(to right, #3f358e, #683188, #714b7d);
					z-index: 3;
				}
			}
		}

		&::before {
			content: '';
			position: absolute;
			left: -1px;
			top: 32px;
			width: 3px;
			height: calc(100% - 64px);
			background-color: #fff;
			z-index: 1;
		}

		&-header {
			h3 {
				font-size: 20px;
				line-height: normal;
				font-weight: 600;
				margin-bottom: 4px;
			}

			p {
				color: var(--sol-app-sidebar-color);
			}
		}

		.infor-decor {
			position: absolute;
			left: -16px;
			top: 0;
			width: 32px;
			height: 32px;
			border-radius: 50%;
			border: 2px solid #fff; /* Outer border */
			display: flex;
			justify-content: center;
			align-items: center;
			overflow: hidden;
			z-index: 2;

			&-inner {
				width: 10px;
				height: 10px;
				border-radius: 50%;
				background-color: #fff; /* Inner circle color */
			}
		}

		.infor-decor-bottom {
			position: absolute;
			left: -16px;
			bottom: 0;
			width: 32px;
			height: 32px;
			border-radius: 50%;
			border: 2px solid #fff; /* Outer border */
		}
		.basic-multi-select {
			.select__control {
				background-color: var(--sol-app-card-bg);
				border: none;
				border-radius: 8px;
				height: 32px;
				min-height: unset;
				display: flex;
				align-items: center;

				.select__value-container {
					height: 32px;
					padding: 0 10px;
				}

				.select__indicators {
					display: none;
				}

				.select__multi-value {
					margin: 0 2px;
					border-radius: 6px;
					overflow: hidden;
					.select__multi-value__remove {
						background-color: inherit;
						color: #000;
					}
				}

				.select__input-container {
					color: #fff;
				}

				.select__placeholder {
					color: var(--sol-app-sidebar-color);
				}
			}

			.select__control--is-focused {
				outline: 0;
				border-color: var(--sol-app-input-border);
				box-shadow: none;
				border: 1px solid var(--sol-app-input-border);
			}
		}
	}
	&-suggestion {
		span {
			font-size: 16px;
			line-height: normal;
			color: var(--sol-app-sidebar-color);
		}

		.options {
			color: #fff;
			padding: 0.25rem;
			border-radius: 8px;
			background-color: rgb(71 85 105 / 1);
		}
	}

	&-preview {
		background-color: rgb(34, 34, 62);
		padding: 24px;
		border-radius: 8px;
		margin-bottom: 16px;
		&-header {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.infor {
				display: flex;
				align-items: center;
				gap: 12px;
			}

			.avatar {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 64px;
				width: 64px;
				border-radius: 50%;
				background: rgb(58, 96, 36);
				font-size: 24px;
				line-height: normal;
				font-weight: 800;
			}

			&-content {
				display: flex;
				flex-direction: column;
				gap: 4px;

				span {
					font-size: 12px;
					line-height: normal;
					background-color: rgb(253 224 71 / 1);
					padding: 4px 6px;
					border-radius: 6px;
					color: #000;
				}

				&-title {
					color: rgb(96 165 250 / 1);
					font-size: 16px;
					line-height: normal;
					font-weight: 600;
				}
			}
		}

		&-main {
			margin-top: 24px;
			background-color: rgb(28 28 51 / 1);
			padding: 12px 24px;
			border-radius: 8px;

			h2 {
				font-size: 20px;
				line-height: normal;
				font-weight: 600;
				margin-bottom: 4px;
			}
		}
	}
}
