.sol-lottery-detail-template {
	padding-top: 66px;
	.left-panel {
		background: transparent linear-gradient(180deg, #1c1246 0%, #0d0f31 100%) 0%
			0% no-repeat padding-box;
		border: 1px solid #48378d;
		border-radius: 19px;
		padding: 114px 20px 20px;
		height: 100%;
	}
	.right-panel {
		background: transparent linear-gradient(180deg, #1c1246 0%, #0d0f31 100%) 0%
			0% no-repeat padding-box;
		border: 1px solid #48378d;
		border-radius: 20px;
		padding: 24px 24px 16px;
		height: 100%;
	}
}
