.sol-page-title {
	color: var(--sol-color-heading);
	font-size: 40px;
	line-height: 50px;
	font-weight: bold;
	text-transform: uppercase;
	margin-bottom: 44px;
	@media(max-width:1199px){
		font-size: 30px;
		line-height: 1.3;
		margin-bottom: 20px;
	}
    @media(max-width:767px){
		font-size: 26px;
	}
}
