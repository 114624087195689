.sol-win-lotteries {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;

    &-label {
        font-size: 20px;
        line-height: normal;
        font-weight: 600;
    }

    .sol-text-running {
        margin-top: 2px;
        overflow: hidden;
        color: var(--sol-color-primary);
        font-size: 14px;
        font-weight: 700;

    }
}