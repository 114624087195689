.sol-tab {
	flex: 1;
	background-color: transparent;
	border: 0;
	display: flex;
	justify-content: center;
	padding: 18px 30px 20px 30px;
	gap: 10px;
	border-bottom: 3px solid transparent;
	color: var(--sol-color);
	font-size: 18px;
	font-weight: 700;
	text-transform: uppercase;
	cursor: pointer;
	transition: all ease-in-out 0.3s;
	outline: 0;
	@media (max-width: 1399px) {
		padding: 15px 24px;
	}
	@media (max-width: 1199px) {
		font-size: 16px;
	}
	@media (max-width: 767px) {
		font-size: 14px;
		padding: 15px 18px;
	}
	&:disabled {
		pointer-events: none;
		opacity: 0.5;
	}
	&:not(:disabled) {
		&:hover {
			color: var(--sol-color-heading);
		}
	}
	&.active {
		color: var(--sol-color-heading);
		border-color: var(--sol-color-primary);
	}
}
