.sol-progress-bar {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	row-gap: 30px;
	border-radius: 8px;
	background: var(--sol-border-secondary);
	height: 10px;
	.sol-progress-bar-inner {
		height: 10px;
		border-radius: 8px;
		background: linear-gradient(
			45deg,
			rgb(0, 63, 221) 0%,
			rgb(24, 255, 113) 100%
		);
	}
	&-lg {
		@media (min-width: 1200px) {
			height: 15px;
			.sol-progress-bar-inner {
				height: 15px;
				border-radius: 8px;
				background: linear-gradient(
					45deg,
					rgb(0, 63, 221) 0%,
					rgb(24, 255, 113) 100%
				);
			}
		}
	}
}

.sol-progress-bar-outer {
	display: flex;
	flex-direction: column;
	row-gap: 30px;
}
