.sol-lottery-info {
	position: relative;

	display: flex;
	flex-direction: column;

	.information-state {
		/* HTML: <div class="ribbon">Your text content</div> */
		/* HTML: <div class="ribbon">Your text content</div> */
		&.ribbon {
			font-size: 15px;
			font-weight: bold;
			color: #fff;
			width: 100%;
		    text-align: center;
		}
		&.ribbon {
			--c: #cc333f;

			&.mega {
				--c: #9345ec;
			}

			&.king {
				--c: rgb(255, 249, 100);
				color: #000;
			}

			&.meta {
				--c: rgb(0, 255, 213);
				color: #000;
			}
			/* control the cutout part (adjust each variable to understand what it does) */
			--r: 0.8em;
			--a: 60deg; /* from 0 to 90deg */
			--d: 0.5em;

			padding-inline: 0.3em;
			margin: calc(0.5lh + var(--r)) calc(1.2lh + var(--d)) 0;
			line-height: 1.75;
			background: var(--c);
			position: relative;
			white-space: nowrap;
		}
		&.ribbon:before,
		&.ribbon:after {
			content: '';
			position: absolute;
			width: calc(1lh + var(--d));
			height: calc(1.5lh + var(--r));
			bottom: 0;
			border: solid var(--c);
			box-sizing: border-box;
		}
		&.ribbon:before {
			left: 99%;
			border-width: 0 1lh 1lh 0;
			border-radius: 0 0 999px 0;
			clip-path: polygon(
				calc(999px * cos(var(--a)))
					calc(100% - 1lh - var(--d) + 999px * sin(var(--a))),
				999px 0,
				100% 0,
				calc(50% + var(--d) / 2) var(--r),
				var(--d) 0,
				0 0,
				0 calc(100% - 1lh - var(--d))
			);
			transform-origin: 0 calc(100% - 1lh - var(--d));
			rotate: calc(90deg - var(--a));
		}
		&.ribbon:after {
			right: 99%;
			border-width: 0 0 1lh 1lh;
			border-radius: 0 0 0 999px;
			clip-path: polygon(
				calc(100% - 999px * cos(var(--a)))
					calc(100% - 1lh - var(--d) + 999px * sin(var(--a))),
				-999px 0,
				0 0,
				calc(50% - var(--d) / 2) var(--r),
				calc(100% - var(--d)) 0,
				100% 0,
				100% calc(100% - 1lh - var(--d))
			);
			transform-origin: 100% calc(100% - 1lh - var(--d));
			rotate: calc(var(--a) - 90deg);
		}
	}
	.image {
		position: absolute;
		top: calc(-153px - 27px);
		left: 50%;
		transform: translateX(-50%);
		height: 153px;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			max-height: 100%;
			max-width: 100%;
			object-fit: contain;
		}
	}
	.name {
		font: normal normal medium 32px/75px Ubuntu;
		font-size: 32px;
		line-height: 36px;
		font-weight: 600;
		letter-spacing: 0px;
		color: #9345ec;
		margin-bottom: 12px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		text-align: center;
		margin-top: 16px;

		&.mega {
			color: #9345ec;
		}

		&.king {
			color: rgb(255, 249, 100);
		}

		&.meta {
			color: rgb(0, 255, 213);
		}
	}
	.price {
		margin-bottom: 22px;
		padding-bottom: 16px;
		position: relative;
		text-align: center;
		font-size: 32px;
		line-height: normal;
		font-weight: 600;
		white-space: nowrap;
		letter-spacing: 0px;
		color: #ffffff;
		&::after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			width: 87px;
			height: 4px;
			background: #9345ec 0% 0% no-repeat padding-box;
			border-radius: 4px;
		}
		&.mega::after {
			background: #9345ec 0% 0% no-repeat padding-box;
		}

		&.king::after {
			background: rgb(255, 249, 100) 0% 0% no-repeat padding-box;
		}

		&.meta::after {
			background: rgb(0, 255, 213) 0% 0% no-repeat padding-box;
		}
		
	}
	.description {
		font-size: 12px;
		line-height: 16px;
		color: var(--sol-color);
		margin-bottom: 12px;
		text-align: center;
	}
	.state {
		font-size: 24px;
		line-height: 28px;
		color: var(--sol-color-primary);
		margin-bottom: 16px;
		text-align: center;
		font-weight: 700;

		&.O {
			color: #ff0000;
		}

		&.U {
			color: #00ff00;
		}

		&.C {
			color: #808080;
		}

		&.RF {
			color: #23e777;
		}

		&.RA {
			color: #ffff00;
		}
	}
	.state-drawing {
		font-size: 18px;
		line-height: 24px;
		color: #00ff00;
		margin-bottom: 16px;
		text-align: center;
		font-weight: 500;
	}
	.countdown {
		height: 64px;
		margin-bottom: 16px;
		border-radius: 12px;
		display: flex;
		justify-content: center;
		align-items: center;
		column-gap: 10px;
		.end {
			height: 100%;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 12px;
			background: #261a55 0% 0% no-repeat padding-box;
		}
		.item {
			flex: 1;
			background: #261a55 0% 0% no-repeat padding-box;
			border-radius: 12px;
			padding: 6px 10px;
			display: flex;
			flex-direction: column;
			align-items: center;
			row-gap: 4px;
			b {
				font-size: 24px;
				font-weight: 600;
				line-height: normal;
				letter-spacing: 0px;
				color: #ffffff;
			}
			span {
				font-size: 12px;
				line-height: 13px;
				letter-spacing: 0px;
				color: #ffffff;
			}
		}
	}
	.action {
		text-align: center;
		margin-bottom: 40px;

		.sol-btn {
			min-width: 155px;
		}
	}

	.information {
		margin-bottom: 30px;

		h4 {
			font-size: 16px;
			line-height: 20px;
			font-weight: 600;
			letter-spacing: 0px;
			color: var(--sol-color);
			margin-bottom: 14px;
		}
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			li {
				display: flex;
				align-items: center;
				justify-content: space-between;
				column-gap: 20px;
				padding: 8px 0;
				border-top: 1px solid #48378d;
				color: #afafaf;
				font-size: 15px;
			}
		}
	}
}
